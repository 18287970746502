import ContainerMain from "src/pages/common/ContainerMain"
import logo from "images/logo_onpoint.svg"
import downloadIcon from "images/Company/Media/icon-download.svg"
import "./Library.scss"
import { useFormatter } from "helpers/i18n"

export default function Library() {
  const { __ } = useFormatter()
  return (
    <ContainerMain>
      <div className="media-library">
        <div className="media-library-logo">
          <div className="logo">
            <img className="img-logo" src={logo} />
          </div>
          <div className="logo-download">
            <div className="download-description">
              <div className="description-1">
                {__({
                  defaultMessage: "Press Download Kit",
                })}
              </div>
              <div className="description-2">
                {__({
                  defaultMessage: "Download our logo",
                })}
              </div>
            </div>
            <div className="download-button">
              <a className="btn-download" href={logo} download="OnPoint_logo">
                {__({
                  defaultMessage: "Download",
                })}
                <img className="btn-download-icon" src={downloadIcon} />
              </a>
            </div>
          </div>
        </div>
        <div className="media-library-decriptions">
          <div className="media-library-title">
            {__({
              defaultMessage: "Media Library",
            })}
          </div>
          <div className="media-library-title-1">
            {__({
              defaultMessage: "About OnPoint",
            })}
          </div>
          <div className="media-library-description-1">
            <p>
              {__({
                defaultMessage:
                  "OnPoint is the leading e-commerce enabler in Southeast Asia. Our mission is to develop a comprehensive ecosystem and help businesses thrive online. We invest in technology and build the strongest team in the market with outstanding e-commerce expertise and passion for serving clients. We provide exclusive one-stop solutions and data-driven products with continuous innovations to empower businesses to unlock digital market growth potential.",
              })}
            </p>
          </div>
          <p>
            {__({
              defaultMessage: "Visit us for more information:",
            })}{" "}
            <a href="https://www.onpoint.vn/">https://www.onpoint.vn/</a>
          </p>
        </div>
      </div>
    </ContainerMain>
  )
}
